import {
  getCurrentInstance,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import isEqual from 'lodash/isEqual'
import { useClipboard } from '@vueuse/core'
import axios from 'axios'
import { isEmpty } from 'lodash-es'

import router from '@/router'
import env from '@/libs/env'
import { apiGeneral } from '@/api'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}
/**
 *
 * @returns difference of 2 objects (a,b)
 * @param {*} a Object
 * @param {*} b Object
 *
 */
export const getDifference = (a, b) => {
  const result = Object.entries(a) // [ [key1, value1], [key2, value2], ... ]
    .reduce(
      // eslint-disable-next-line no-return-assign
      (accumulator, [key, value]) => !isEqual(b[key], value)
        ? ((accumulator[key] = a[key]), accumulator)
        : accumulator,
      {},
    ) // initialValue
  return result
}

export const mixFlightTrips = (departure, arrival) => {
  const startPoints = [departure.IATACode]
  const endPoints = [arrival.IATACode]
  return startPoints.flatMap(start => endPoints.map(end => `${start}-${end}`))
}

export const mixCountryTrips = (departure, arrival) => {
  const startPoints = [departure.country]
  const endPoints = [arrival.country]
  return startPoints.flatMap(start => endPoints.map(end => `${start?.toUpperCase()}-${end?.toUpperCase()}`))
}

export const mixRegionTrips = (departure, arrival) => {
  const startPoints = [departure.region]
  const endPoints = [arrival.region]
  return startPoints.flatMap(start => endPoints.map(end => `${start?.toUpperCase()}-${end?.toUpperCase()}`))
}

export const comparePoint = (value, sample) => {
  const isNegative = sample.startsWith('!')
  if (isNegative) {
    sample = sample.substr(1)
    return value.length === sample.length && value !== sample
  }
  return value === sample
}

export const compareTrip = (flightTrip, configTrip) => {
  const flightPoints = flightTrip.split('-')
  const configPoints = configTrip.split('-')
  return (
    comparePoint(flightPoints[0], configPoints[0])
    && comparePoint(flightPoints[1], configPoints[1])
  )
}

export const calculatePromotion = (promotion, fareItem, adult, child, segments) => {
  const promotionAdult = promotion
    ? Math.min(
      promotion.discountAmount + (promotion.discountPercent / 100) * fareItem.fareAdult,
      fareItem.fareAdult,
    ) : 0
  const promotionChild = promotion
    ? Math.min(
      promotion.discountAmount + (promotion.discountPercent / 100) * fareItem.fareChild,
      fareItem.fareChild,
    ) : 0
  return {
    promotionAdult,
    promotionChild,
    promotionInfant: 0,
    code: promotion?.code,
    paidType: promotion?.paidType,
    discountAmount: promotionAdult * adult + promotionChild * child,
    discountCounter: adult + child,
    segmentId: segments?.[0]?.segmentId,
    segments: segments?.map(segment => ({
      ...segment,
      departureDate: segment.departureTime.substr(0, 10),
      arrivalDate: segment.arrivalTime.substr(0, 10),
    })),
  }
}

// export const getAppName = () => process.env.VUE_APP_NAME
// export const getAppLogoFull = () => process.env.VUE_APP_LOGO_FULL_URL
// export const getAppLogoFavicon = () => process.env.VUE_APP_LOGO_FAVICON_URL

export const toDataUrl = async url => new Promise(resolve => {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then(data => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(data.data)
    })
    .catch(() => {
      resolve(url)
    })
})

export const downloadURL = (url, name) => {
  const link = document.createElement('a')
  link.download = name
  link.href = url
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const setFavicon = url => {
  let link = document.querySelector("link[rel='icon']")
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.head.appendChild(link)
  }
  link.href = url
}

export const extractNumber = employee => {
  if (employee.scheduleCode) return employee.scheduleCode
  const match = employee.username.match(/\d+/)
  return match ? parseInt(match[0], 10) : 0
}

export function logoAirlineUrl(airline, ext = 'png') {
  return `${env.airlineLogoUrl}/${airline}.${ext}`
}

export function upperFirstPerWord(str, seperate = ' ') {
  if (!str) return str
  const arr = str.split(seperate)
  return arr
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(seperate)
}

export const shortenUrl = (url, maxlength = 70) => {
  if (!url || url.length < maxlength) return url
  const urlCut = url.replace(/^(https?:\/\/|www\.)/, '')
  const urlArray = urlCut.split('/')
  if (urlArray.length < 2) return url
  return `${urlArray[0]}/.../${urlArray[urlArray.length - 1]}`
}

export const stringOrArrayToArray = value => [value].flatMap(i => i).filter(Boolean)
export const stringOrArrayToString = value => stringOrArrayToArray(value)[0] ?? ''

// convert base64 to file
export function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// NOTE FOR UPLOAD IMAGE
// upload files[]
export async function uploadFilesDocuments(files) {
  const filesFromData = files.map(file => {
    if (file?.file && file?.url) return false
    const data = new FormData()
    data.append('file', file, file.name)

    return {
      data,
      fileName: file.name,
    }
  })

  return Promise.all(filesFromData.filter(item => !!item).map(async item => {
    const res = await apiGeneral.uploadImage(item.data)

    return {
      url: res.url,
      file: item.fileName,
    }
  }))
}

// function copy text
export function useCopyText() {
  const disabledCopy = ref(false)
  // const { toastSuccess, toastError } = useToast()
  const { copy: copyByClipboard } = useClipboard()
  function copy(text) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copyByClipboard(text)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  return { copy, disabledCopy }
}

export function getShortNameTripByMultiSegments(segments) { // use in modify 'HAN-SGN-SGN-NRT' => 'HAN-SGN-NRT'
  if (isEmpty(segments)) return ''
  const segmentNames = segments.map(segment => `${segment.departure.iataCode}-${segment.arrival.iataCode}`)
  const flattenedNames = segmentNames.join('-').split('-')
  const uniqueNames = flattenedNames.filter((code, index) => code !== flattenedNames[index + 1])
  return uniqueNames.join('-')
}

export const arrayToObject = (array, key = 'value', value = 'label') => array.reduce((a, c) => ({ ...a, [c[key]]: c[value] }), {})

export function parseJsonToObjectOrText(text) {
  if (!text) return text
  try {
    return (JSON.parse(text?.trim()))
  } catch (error) {
    return text?.trim()
  }
}
